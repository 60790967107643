import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { User } from '../models/user.model';
import { PasswordResponse } from '../models/password-response.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private endpoit: string = environment.server + 'api/Users/';

    constructor(
        private httpClient: HttpClient,
        private router: Router
    ) { }

  getUsers(): Observable<User[]>  {
    return this.httpClient
        .get<User[]>(this.endpoit + 'GetUserPermision')
        .pipe();
  }

  getUserInstanceById(userId:string){
    return this.httpClient
        .get<User>(this.endpoit + 'GetUserPermisionByUserId?userId='+userId)
        .pipe();
  }

  createUser(request:User): Observable<PasswordResponse> {
    return this.httpClient
    .post<PasswordResponse>(this.endpoit + 'register',
      request
    )
    .pipe();
  }

  updateUser(request:User) {
    return this.httpClient
    .post(this.endpoit + 'update',
      request
    )
    .pipe();
  }

  deleteUser(name:string) {
    return this.httpClient
    .post(this.endpoit + 'delete',
      {
        "userName":name
      }
    )
    .pipe();
  }

  enableDisableUser(name:string, state:boolean) {
    return this.httpClient
    .post(this.endpoit + 'EnableDisable',
      {
        "userName":name,
        "enable": state
      }
    )
    .pipe();
  }

  createPermisionInstance(userId:string, instanceId:string) {
    return this.httpClient
    .post(this.endpoit + 'createpermision',
      {
        "userId":userId,
        "instanceId": instanceId
      }
    )
    .pipe();
  }

  deletePermisionInstance(userId:string, instanceId:string) {
    return this.httpClient
    .post(this.endpoit + 'deletepermision',
      {
        "userId":userId,
        "instanceId": instanceId
      }
    )
    .pipe();
  }

  assignAllPermisions(userId:string, assign:boolean){
    return this.httpClient
    .post(this.endpoit + 'AssignUnassignPermision',
      {
        "userId":userId,
        "assing": assign
      }
    )
    .pipe();
  }

  updatePassword(username:string, password:string, generatePassword:boolean, passwordChange:boolean, sendEmail:boolean):Observable<PasswordResponse>{
    return this.httpClient
    .post<PasswordResponse>(this.endpoit + 'ChangePassword',
      {
        "username": username,
        "newPassword": password,
        "generatePassword": generatePassword,
        "passwordChangeRequired": passwordChange,
        "sendEmail": sendEmail
      }
    )
    .pipe();
  }
}
